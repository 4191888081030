import React from 'react'
import { TableRowCell } from '../../Table'
import { downTrendStyling } from '../../../helpers/downTrendStyling'
import type { AdGroupMetric } from '../types'

interface TableRowProps {
  key: number
  item: AdGroupMetric
  isMeta?: boolean
}

export function TableRow({ item, isMeta }: TableRowProps) {
  const {
    adGroupName,
    adSpend,
    ctr,
    cpc,
    cr,
    clicks,
    conversions,
    impressions,
    costPerConv,
    conversionsValue
  } = item

  return (
    <tr className="table-tr">
      <TableRowCell label={adGroupName || ''} className="p-4 text-left" />
      <TableRowCell label={(clicks || 0).toLocaleString()} />
      <TableRowCell label={(impressions || 0).toLocaleString()} />
      <TableRowCell label={`${ctr || 0}%`} />
      <TableRowCell label={`$${cpc || 0}`} />
      <TableRowCell label={`$${adSpend || 0}`} />
      {!isMeta && (
        <>
          <TableRowCell label={`${cr || 0}%`} />
          <TableRowCell
            label={Math.round(conversions || 0).toLocaleString()}
            labelClassName={downTrendStyling((conversions || 0) === 0)}
          />
          <TableRowCell label={`$${(conversionsValue || 0).toLocaleString()}`} />
          <TableRowCell label={`$${(costPerConv || 0).toLocaleString()}`} />
        </>
      )}
    </tr>
  )
}
