import React from 'react'
import { TableHeadCell } from '../../Table'
import { adsFindTerm } from '../../../helpers/adsFindTerm'

export function TableHead({ isMeta }: { isMeta?: boolean }) {
  return (
    <tr>
      <TableHeadCell label="Ad Group" className="text-left" tooltip={adsFindTerm('adGroup')} />
      <TableHeadCell label="Clicks" tooltip={adsFindTerm('clicks')} />
      <TableHeadCell label="Impr." tooltip={adsFindTerm('impressions')} />
      <TableHeadCell label="CTR" tooltip={adsFindTerm('ctr')} />
      <TableHeadCell label="Avg CPC" tooltip={adsFindTerm('averageCpc')} />
      <TableHeadCell label="Cost" tooltip={adsFindTerm('adSpend')} />
      {!isMeta && (
        <>
          <TableHeadCell label="Conv. Rate" tooltip={adsFindTerm('cr')} />
          <TableHeadCell label="Conv." tooltip={adsFindTerm('conversions')} />
          <TableHeadCell label="Conv. Value" tooltip={adsFindTerm('conversionValue')} />
          <TableHeadCell label="Cost/Conv." tooltip={adsFindTerm('costPerConv')} />
        </>
      )}
    </tr>
  )
}
