import React from 'react'
import { logout } from '../../services/auth'

interface ProfileBadgeProps {
  username: string
  email: string
  expanded: boolean
}

export function ProfileBadge({ username, email, expanded }: ProfileBadgeProps) {

  return (
    <div
      className={`flex items-center gap-[12px] border-gray-200 dark:border-gray-600 border-t-[1px] ${expanded ? 'pl-[8px] pr-[32px] pt-[24px]' : 'pt-[24px] justify-center self-start'}`}
    >
      <div className="select-none rounded-[200px] bg-gray-100 dark:bg-gray-700 border-[0.75px] border-gray-200 dark:border-gray-900 w-[40px] min-w-[40px] h-[40px] font-inter text-[16px] font-semibold flex items-center justify-center text-gray-600 dark:text-gray-100">
        {username[0].toUpperCase()}
      </div>
      {expanded && (
        <div className="flex flex-col w-full">
          <span className="font-inter text-[14px] font-semibold text-gray-700 dark:text-gray-200 overflow-wrap-anywhere">
            {username}
          </span>
          <span className="font-inter text-[12px] font-normal text-gray-700 dark:text-gray-200 overflow-wrap-anywhere">
            {email}
          </span>
          <a
            onClick={logout}
            href="/auth/logout"
            className="text-gray-600 dark:text-gray-100 font-inter text-[12px] font-[400] leading-[18px] pt-[8px] underline hover:text-blue-700 dark:hover:text-blue-600"
          >
            Sign out
          </a>
        </div>
      )}
    </div>
  )
}
