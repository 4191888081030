import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from '../components/Tabs'
import { Summary } from '../components/seo/Summary'
import { Top10Keywords } from '../components/seo/Top10Keywords'
import { KeywordMovers } from '../components/seo/KeywordMovers'
import { TargetedKeywords } from '../components/seo/TargetedKeywords'
import { NewlyRanked } from '../components/seo/NewlyRanked'
import { RankingKeywords } from '../components/seo/RankingKeywords'
import { ProductInactiveMessage, ProductMessages } from '../components/seo/ProductMessages'
import { GettingDataReady } from '../components/GettingDataReady'
import { DomainCheck } from '../components/seo/DomainCheck'
import { useGetDomainStatsQuery } from '../services/checkout'
import { useGetSeoDomainReportsQuery } from '../services/reports'
import {
  useGetSeoKeywordsQuery,
  useGetSeoTop10KeywordsQuery,
  useGetSeoWebsiteQuery
} from '../services/seo'
import { getMessages } from '../components/ProductMessage'
import { useSeoCache } from '../services/cache'
import { AccountSelector } from '../components/AccountSelector'
import { LocationSelector } from '../components/LocationSelector'
import { extractAccessKey } from '../helpers/extractAccessKey'
import { useCheckAccessKeyQuery } from '../services/cpanel'
import { BreadcrumbComponent } from '../components/Breacrumbs'

const accessKey = extractAccessKey()

const TAB_ALL = 'All'
const TAB_RANKING_KEYWORDS = 'Ranking Keywords'
const TAB_TOP_10_KEYWORDS = 'Top 10 Keywords'
const TAB_KEYWORD_MOVERS = 'Top 25 Movers'
const TAB_TARGETED_KEYWORDS = 'Targeted Keywords'
const TAB_NEWLY_RANKED = 'Newly Ranking'

const sortByGoogleCurrent = (a, b) => {
  // sort null values last
  if (a.Google_Current__c === null) return 1
  if (b.Google_Current__c === null) return -1
  return a.Google_Current__c - b.Google_Current__c
}
function filterByLocation(records, location) {
  return records
    .filter((item) => item.Geolocation_Effective__c.toUpperCase().includes(location.toUpperCase()))
    .sort((a, b) => a.Google_DaysAgo28__c - b.Google_DaysAgo28__c)
}

export function SeoPage() {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [location, setLocation] = useState('All')

  const rankings = window.t.dashboard.seo.titles.rankings
  const accessKeyValid = useCheckAccessKeyQuery(accessKey)

  const pagination = useSelector((store) => store.seo.pagination)
  const hideMessages = useSelector((store) => store.seo.messages).hide
  const selectedSeoId = useSelector((store) => store.seo.selectedAccountId)
  const customer = useSelector((store) => store.global.customer)

  useEffect(() => {
    useSeoCache(selectedSeoId)
  }, [selectedSeoId])

  const website = useGetSeoWebsiteQuery(selectedSeoId, { skip: !selectedSeoId })
  const targetedKeywords = useGetSeoKeywordsQuery(selectedSeoId, { skip: !selectedSeoId })
  const top10Keywords = useGetSeoTop10KeywordsQuery(selectedSeoId, { skip: !selectedSeoId })
  const websiteData = website.data?.records || []
  const webDataFiltered = websiteData.filter(
    (v, i, a) => a.findIndex((t) => t.Geolocation__c === v.Geolocation__c) === i
  ) // filter duplicate geolocations
  const selectedWebsite =
    location !== 'All'
      ? [
          webDataFiltered.find((item) =>
            item.Geolocation__c.toUpperCase().includes(location.toUpperCase())
          )
        ]
      : webDataFiltered
  const websiteHost = webDataFiltered[0]?.website_url__c
  const product = website.data?.product || {}
  const domain = useGetDomainStatsQuery(websiteHost, { skip: !websiteHost })
  const url = domain?.data?.url || null
  const domainStatsData = useGetSeoDomainReportsQuery(url, { skip: !url })

  const { performanceCharts, rankingKeywords, keywordCharts, ...domainStats } =
    domainStatsData.data || {}
  const performanceChartsFiltered = performanceCharts
    ? performanceCharts.map(({ date, org_traffic }) => ({ date, org_traffic }))
    : {} // remove unrelated properties (and only keep org_traffic)

  const keywordsSummary = targetedKeywords?.data?.summary || {}

  let top10KeywordsList = [...(top10Keywords?.data?.records || [])].map((item) => ({
    ...item,
    Google_DaysAgo28__c: item.Google_DaysAgo28__c || 100
  }))

  let topKeywordMovers = [...(targetedKeywords?.data?.movers || [])].sort(sortByGoogleCurrent)
  let targetedKeywordsList = [...(targetedKeywords?.data?.records || [])].sort(sortByGoogleCurrent)
  let newlyRanked = [...(targetedKeywords?.data?.newrankings || [])]
  let rankingKeywordsList = typeof rankingKeywords === 'object'
    ? rankingKeywords || []
    : JSON.parse(rankingKeywords || '[]')

  if (location !== 'All') {
    top10KeywordsList = filterByLocation(top10KeywordsList, location)
    topKeywordMovers = filterByLocation(topKeywordMovers, location)
    targetedKeywordsList = filterByLocation(targetedKeywordsList, location)
    newlyRanked = newlyRanked.filter((item) =>
      item.Geolocation_Effective__c.toUpperCase().includes(location.toUpperCase())
    )
    rankingKeywordsList = rankingKeywordsList.filter((item) =>
      item.keyword_merged.toUpperCase().includes(location.toUpperCase())
    )
  }

  const tabs = [
    TAB_ALL,
    rankingKeywordsList.length > 0 && TAB_RANKING_KEYWORDS,
    top10KeywordsList.length > 0 && TAB_TOP_10_KEYWORDS,
    topKeywordMovers.length > 0 && TAB_KEYWORD_MOVERS,
    targetedKeywordsList.length > 0 && TAB_TARGETED_KEYWORDS,
    newlyRanked.length > 0 && TAB_NEWLY_RANKED
  ].filter(Boolean)

  // Render the ProductInactiveMessage conditionally
  if (!customer.seoActive && !accessKeyValid.data) {
    return <ProductInactiveMessage />
  }

  const tabIsActive = (tabLabel: string) => [TAB_ALL, tabLabel].includes(tabs[activeTabIndex])

  return (
    <>
      {!hideMessages && (
        <ProductMessages
          loading={website.isLoading}
          messages={getMessages(product.setupComplete, product.activeDays)}
        />
      )}
      <BreadcrumbComponent />
      <div className="flex flex-col sm:flex-row gap-[16px] items-start sm:items-center pt-[5px] sm:pt-[16px] pb-[5px] sm:b-[24px]">
        <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px]">
          SEO Summary
        </h1>
        <div className="flex flex-col sm:flex-row gap-[16px] items-start sm:items-center">
          <AccountSelector type="seo" />
          <LocationSelector
            location={location}
            setLocation={setLocation}
            websiteData={websiteData}
          />
        </div>
      </div>
      {tabs.length > 2 && (
        <Tabs labels={tabs} activeTabIndex={activeTabIndex} onSelect={setActiveTabIndex} />
      )}
      <DomainCheck
        loading={website.isLoading}
        websiteData={selectedWebsite}
        domain={domain.data || {}}
      />

      <div className="gap-4 grid grid-cols-1 w-full">
        {website.isLoading && <GettingDataReady />}

        {!website.isLoading && tabIsActive(TAB_ALL) && (
          <Summary
            rankings={rankings}
            domainStats={domainStats}
            keywordsSummary={keywordsSummary}
            performance={performanceChartsFiltered}
            keywords={keywordCharts}
            newlyRanking={newlyRanked}
            isLoadingDomainStats={domainStatsData.isLoading}
            isLoadingKeywords={targetedKeywords.isLoading}
            seoProduct={product}
            domainStatStatus={domainStatsData.status}
          />
        )}
        {rankingKeywordsList.length > 0 && tabIsActive(TAB_RANKING_KEYWORDS) && (
          <RankingKeywords
            rankings={rankings}
            list={rankingKeywordsList}
            loading={domainStatsData.isLoading}
            pagination={pagination.rankingKeywords}
          />
        )}
        {top10KeywordsList.length > 0 && tabIsActive(TAB_TOP_10_KEYWORDS) && (
          <Top10Keywords
            rankings={rankings}
            list={top10KeywordsList}
            loading={top10Keywords.isLoading}
          />
        )}
        {topKeywordMovers.length > 0 && tabIsActive(TAB_KEYWORD_MOVERS) && (
          <KeywordMovers
            rankings={rankings}
            list={topKeywordMovers}
            loading={targetedKeywords.isLoading}
            pagination={pagination.topKeywordMovers}
          />
        )}
        {targetedKeywordsList.length > 0 && tabIsActive(TAB_TARGETED_KEYWORDS) && (
          <TargetedKeywords
            rankings={rankings}
            list={targetedKeywordsList}
            loading={targetedKeywords.isLoading}
            pagination={pagination.targetedKeywords}
          />
        )}
        {newlyRanked.length > 0 && tabIsActive(TAB_NEWLY_RANKED) && (
          <NewlyRanked
            rankings={rankings}
            list={newlyRanked}
            loading={targetedKeywords.isLoading}
            pagination={pagination.newlyRanked}
          />
        )}
      </div>
    </>
  )
}
