import spacetime from 'spacetime'
import { api } from './api'
import { store } from '../store'

export const openCacheDB = async (customerId) => new Promise((resolve, reject) => {
  const db = indexedDB.open(`cpanel-${customerId}`, 1)
  
  db.onupgradeneeded = (event) => {
    const db = event.target.result
    const objectStore = db.createObjectStore('cache', { keyPath: 'name'})
  }
  db.onsuccess = (event) => {
    resolve(event.target.result)
  }
  db.onerror = (event) => {
    console.error('Error opening cache DB:', event.target.error)
    reject()
  }
})

export const clearCustomerCache = async () => {
  const customerId = store.getState().global.customer.id || 'unknown'
  indexedDB.deleteDatabase(`cpanel-${customerId}`)
}

export const deleteAllCacheDB = async () => {
  indexedDB.databases().then((dbs) => dbs.forEach((db) => indexedDB.deleteDatabase(db.name)))
}

const applyCacheToApi = (endpoint, id, data) =>
  store.dispatch(api.util.upsertQueryData(endpoint, id, data))

const today = () => spacetime.now().format('iso-short')

const readFromStorage = (key) => {
  const customerId = store.getState().global.customer.id || 'unknown'
  try {
    openCacheDB(customerId).then((db) => {
      const transaction = db.transaction('cache', 'readonly')
      const objectStore = transaction.objectStore('cache')
      return objectStore.get(key).onsuccess = (event) => event.target.result
    })
  } catch (error) {
    return null
  }
}

const cacheAccessor = (name, apiName) => ({
  name,
  get: () => {
    const { data, date } = readFromStorage(name) || {}

    return data && date === today() ? data : null
  },
  getByArg: (arg) => {
    const id = JSON.stringify(arg)
    const cached = cacheAccessor(name, apiName).get()
    if (!Array.isArray(cached)) {
      return null
    }

    const { data } = cached.find((item) => item.id === id) || {}

    return data || null
  },
  set: (arg, data) => {
    const id = JSON.stringify(arg)
    const cached = cacheAccessor(name, apiName).get()
    const otherCache = Array.isArray(cached) ? cached.filter((item) => item.id !== id) : []

    const customerId = store.getState().global.customer.id || 'unknown'

    openCacheDB(customerId).then((db)=> {
      const transaction = db.transaction('cache', 'readwrite')
      const objectStore = transaction.objectStore('cache')
      objectStore.add({
        name,
        date: today(),
        data: [...otherCache, {id, data}]
      })
    })
  },
  use: (arg) => {
    const data = cacheAccessor(name, apiName).getByArg(arg)

    if (data) {
      applyCacheToApi(apiName, arg, data)
    }
  },
  clear: () => {
    const customerId = store.getState().global.customer.id || 'unknown'
    openCacheDB(customerId).then((db) => {
      const transaction = db.transaction('cache', 'readwrite')
      const objectStore = transaction.objectStore('cache')
      objectStore.delete(name)
    })
  }
})


export const cache = {
  seoWebsite: cacheAccessor('seo.website', 'getSeoWebsite'),
  seoKeywords: cacheAccessor('seo.keywords', 'getSeoKeywords'),
  seoTop10Keywords: cacheAccessor('seo.top10Keywords', 'getSeoTop10Keywords'),
  seoDomainReports: cacheAccessor('seo.domainReports', 'getSeoDomainReports'),
  seoDomain: cacheAccessor('seo.domain', 'getDomainStats'),
  adsReports: cacheAccessor('ads.reports', 'getAdsReports'),
  adsKeywords: cacheAccessor('ads.keywords', 'getAllAdsReports'),
  adsCampaigns: cacheAccessor('ads.campaigns', 'getAllAdsReports'),
  adsAdGroups: cacheAccessor('ads.adGroups', 'getAllAdsReports'),
  adsTopAds: cacheAccessor('ads.topAds', 'getAllAdsReports'),
  adsShopping: cacheAccessor('ads.shopping', 'getAllAdsReports'),
  searchTerms: cacheAccessor('ads.searchTerms', 'getAllAdsReports'),
  facebookReports: cacheAccessor('facebook.reports', 'getFacebookReports'),
  notifications: cacheAccessor('notifications', 'getNotifications')
}

export const useSeoCache = (seoAccountId) => {
  cache.seoWebsite.use(seoAccountId)
  cache.seoKeywords.use(seoAccountId)
  cache.seoTop10Keywords.use(seoAccountId)

  const host = cache.seoWebsite.getByArg(seoAccountId)?.records?.[0]?.website_url__c
  if (host) {
    cache.seoDomainReports.use(host)
    cache.seoDomain.use(host)
  }
}

export const clearAllCache = () => {
  Object.values(cache).forEach((accessor) => accessor.clear())
}
