import { isRejectedWithValue } from '@reduxjs/toolkit'
import { addToast } from '../store/global'
import { store } from '../store'

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status, data } = action.payload
    switch (status) {
      case 422:
        store.dispatch(
          addToast({
            title: 'Warning',
            subtext: data.warning,
            type: 'warning'
          })
        )
        return
      case 500:
        store.dispatch(
          addToast({
            title: 'Something went wrong!',
            subtext: window.t?.system?.error?.api || data.error || '',
            type: 'error'
          })
        )
        return
      case 403:
        store.dispatch(
          addToast({
            title: window.t?.account?.error?.login || 'Unauthorized',
            subtext: window.t?.account?.error?.login_subText || '',
            type: 'error'
          })
        )
        return
      default:
        store.dispatch(
          addToast({
            title: 'Something went wrong!',
            subtext: data?.error,
            type: 'error'
          })
        )
    }
  }

  return next(action)
}
