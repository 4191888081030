import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from '../components/Tabs'
import { NoDataMessage, ProductMessages } from '../components/ads/ProductMessages'
import { getMessages } from '../components/ProductMessage'
import { GettingDataReady } from '../components/GettingDataReady'
import { useGetFacebookReportsQuery } from '../services/reports'
import { AdGroups } from '../components/ads/AdGroups'
import { Campaigns } from '../components/ads/Campaigns'
import { LoadingCardLong } from '../components/Loading'
import { FbCoreMetrics } from '../components/CoreMetrics'
import { AccountSelector } from '../components/AccountSelector'
import { isProductActive } from '../helpers/isProductActive'
import type { AdGroupMetric } from '../components/ads/types'

const removePrefix = (name) => {
  return (name || '').replace(/^\*?(SLX|GMT|SME|JDM)( \| )?/, '')
}

const TAB_ALL = 'All'
const TAB_CAMPAIGNS = 'Campaigns'
const TAB_AD_SETS = 'Ad Sets'

export function FacebookPage() {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const ref = useRef(null)

  const customer = useSelector((store) => store.global.customer)
  const dateFilter = useSelector((store) => store.global.dateFilter)
  const hideMessages = useSelector((store) => store.facebook.messages).hide
  const pagination = useSelector((store) => store.facebook.pagination)
  const facebookId = useSelector((store) => store.facebook.selectedAccountId)
  const facebookProduct = useSelector((store) =>
    store.global.customer.activeProducts
      .filter(
        (product) =>
          product?.socialMarketing &&
          product?.socialMarketing.status &&
          isProductActive(product.socialMarketing.status) &&
          product.socialMarketing.signupDate !== null
      )
      .map((product) => product?.socialMarketing)
  )[0]

  const { from, to } = dateFilter
  const { data, isLoading, isError } = useGetFacebookReportsQuery(
    { facebookId, from, to },
    { skip: !(facebookId && from && to) }
  )

  if (!customer.facebookActive) {
    return <NoDataMessage platform="Facebook" />
  }

  if (
    !customer.facebookData ||
    (data?.adsetData?.length === 0 && data?.campaignData?.length === 0)
  ) {
    return <NoDataMessage platform="Facebook" />
  }

  const dataKeys = [
    {
      label: 'Clicks',
      tooltip: 'clicks',
      key: 'clicks'
    },
    {
      label: 'Impressions',
      tooltip: 'impressions',
      key: 'impressions'
    },
    {
      label: 'Reach',
      tooltip: 'reach',
      key: 'reach'
    },
    {
      label: 'Amount Spent',
      tooltip: 'adSpend',
      key: 'spend'
    },
    {
      label: 'Average CPC',
      tooltip: 'averageCpc',
      key: 'cpc'
    },
    {
      label: 'Average CPM',
      tooltip: 'cpm',
      key: 'cpm'
    },
    {
      label: 'CTR',
      tooltip: 'ctr',
      key: 'ctr'
    }
  ]

  // Clean brand name traces
  // FIXME: This should be done in the backend and improved query
  const cleanedAdsetData: AdGroupMetric[] = (data?.adsetData || [])
    .map((item) => ({
      ...item,
      adset_name: removePrefix(item.adset_name), // Should it be removed?
      campaignId: item.adset_id,
      adGroupName: removePrefix(item.adset_name),
      clicks: item.clicks || 0,
      impressions: item.impressions || 0,
      conversions: 0,
      adSpend: item.spend || 0,
      ctr: item.ctr || 0,
      cpc: item.cpc || 0,
      cr: 0,
      costPerConv: 0
    }))
    .sort((a, b) => b.clicks - a.clicks)

  const cleanedCampaignData = (data?.campaignData || [])
    .map((item) => ({
      ...item,
      campaign_name: removePrefix(item.campaign_name)
    }))
    .sort((a, b) => b.clicks - a.clicks)

  const product = Object.values(
    (data?.product || []).find((item) => {
      const details = Object.values(item)[0] || {}
      return details.facebookId === facebookId
    }) || {}
  )[0]

  const tabs = [
    TAB_ALL,
    (data?.campaignData || []).length > 0 && TAB_CAMPAIGNS,
    (data?.adsetData || []).length > 0 && TAB_AD_SETS
  ].filter(Boolean)

  const handleViewMore = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
    setActiveTabIndex(1)
  }

  const tabIsActive = (tabLabel: string) => [TAB_ALL, tabLabel].includes(tabs[activeTabIndex])

  return (
    <>
      {!hideMessages && (
        <ProductMessages
          loading={isLoading}
          messages={getMessages(product?.setupComplete, product?.activeDays)}
        />
      )}
      <div className="flex flex-row gap-[16px] items-center pt-[16px] pb-[24px]">
        <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px]">
          Meta Ads Summary
        </h1>
        <AccountSelector type="facebook" />
      </div>
      <div
        className={`gap-4 grid grid-cols-1 ${isLoading ? 'bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-[16px] sm:p-[24px]' : 'md:grid-cols-2 '} w-full`}
      >
        {isLoading && <LoadingCardLong />}
        {!isLoading && facebookProduct && (
          <FbCoreMetrics
            fromDate={from}
            toDate={to}
            data={data}
            handleViewMore={handleViewMore}
            adsProduct={facebookProduct}
          />
        )}
      </div>

      <div className="col-span-full flex items-center justify-between overflow-x-auto dark:scrollbar-thumb-base-50 dark:scrollbar-track-base-700 flex-1 scrollbar-thin scrollbar-thumb scrollbar-thumb-primary-500 scrollbar-thumb-rounded-full scrollbar-track-gray-200 scrollbar-track-rounded-full scrollbar-width-10">
        <div className="hidden md:flex place-items-start">
          <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pt-[16px] pb-[24px]">
            Metrics
          </h1>
        </div>
        <div
          ref={ref}
          className="flex bg-opacity-30 sm:my-3 tracking-tight space-x-2 md:space-x-4 place-items-end"
        >
          <Tabs labels={tabs} activeTabIndex={activeTabIndex} onSelect={setActiveTabIndex} />
        </div>
      </div>

      <div className="gap-4 grid grid-cols-1 w-full">
        {isLoading && <GettingDataReady />}
        {(data?.campaignData || []).length > 0 && tabIsActive(TAB_CAMPAIGNS) && (
          <Campaigns
            loading={isLoading}
            list={cleanedCampaignData || []}
            pagination={pagination.campaigns}
            adsId={facebookId}
            dateFilter={dateFilter}
            platform="facebook"
            dataKeys={dataKeys}
          />
        )}
        {(data?.adsetData || []).length > 0 && tabIsActive(TAB_AD_SETS) && (
          <AdGroups loading={isLoading} list={cleanedAdsetData} dateFrom={dateFilter?.from} isMeta={true}/>
        )}
      </div>
    </>
  )
}
